<template>
  <div>
    <title-bar
      submitBtn
      :submitBtnTitle="$t('review.create')"
      :title="$t('review.create')"
      @submitPressed="submitPressed"
    />
    <reviews-form
      v-if="review"
      :reviewObject="review"
      :action="action"
      @clearAction="action = null"
    />
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import ReviewsForm from "./components/ReviewsForm.vue";

export default {
  components: {
    TitleBar,
    ReviewsForm,
  },
  data() {
    return {
      review: {},
      action: null,
    };
  },
  methods: {
    submitPressed() {
      this.action = "add";
    },
  },
};
</script>
